/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!  WARNING: Do NOT edit this file!  !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Foundation components are not intended to be edited to
 * satisfy specific use cases. If you would like to propose
 * a change to this component, please reach out to a
 * frontend engineer.
 *
 * @author Asa Ayers <asa.ayers@hellosign.com>
 * @author Nathan Buchar <nathan.buchar@hellosign.com>
 */

@import 'colors';
@import 'fonts';
@import '_text';

:local {
  button.button,
  a.button {
    @extend %text-heading5;

    border-radius: 0;
    padding: 8px 12px;
    margin: 5px;
    text-align: center;

    // HTMLAnchorElements are inline by default
    display: inline-block;

    &.marginless {
      margin: 0;
    }

    &.small {
      padding: 4px 8px;
    }

    &.large {
      padding: 12px 16px;
    }

    // Buttons that are processing get :disabled, but not .disabled
    &:disabled {
      cursor: not-allowed;
    }

    &.disabled {
      opacity: 1;
    }

    &:focus {
      box-shadow: 0 0 0 2px
        rgba(var(--dig-Button-primary-hover--background-color), 0.7);

      // Override legacy styles
      outline: none !important;
    }

    &.primary {
      background-color: var(--dig-Button-primary--background-color);
      color: var(--dig-Button-primary--color);

      &:not([disabled]):hover {
        background-color: var(--dig-Button-primary-hover--background-color);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      }

      // :active needs to have at least the same specificity as the :hover rules
      &.processing,
      &:not([disabled]):active {
        background-color: var(--dig-Button-primary-hover--background-color);
      }

      &.disabled {
        background-color: $grey-300;
        color: $color-text-mid;
      }
    }

    &.outline {
      color: var(--dig-Button-primary--background-color);
      background: none;
      border: 1px solid var(--dig-Button-primary--background-color);

      &:not([disabled]):hover {
        background-color: white;
      }

      &[disabled]:hover {
        background: none;
        color: $grey-600;
        border-color: $grey-600;
      }

      // :active needs to have at least the same specificity as the :hover rules
      &.processing,
      &:not([disabled]):active {
        background-color: $blue-100;
        border-color: var(--dig-Button-primary-hover--background-color);
        color: var(--dig-Button-primary-hover--color);
      }

      &.disabled {
        color: $color-text-mid;
        opacity: 0.7;
        border-color: rgba($color-text-mid, 0.7);
      }
    }

    &.subtle {
      color: $grey-900;
      background: none;
      border: none;

      &:not([disabled]):hover {
        background-color: rgba($blue-900, 0.09);
      }

      &[disabled]:hover {
        background: none;
      }

      // :active needs to have at least the same specificity as the :hover rules
      &.processing,
      &:not([disabled]):active {
        background-color: $blue-100;
        color: var(--dig-Button-primary-hover--background-color);
      }
      &.disabled {
        color: $color-text-light;
        opacity: 0.7;
        border-color: rgba($color-text-mid, 0.7);
      }
    }

    &.warning {
      background-color: $red-500;
      color: white;

      &:not([disabled]):hover {
        background-color: $red-400;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      }

      // :active needs to have at least the same specificity as the :hover rules
      &.processing,
      &:not([disabled]):active {
        background-color: $red-600;
      }

      &.disabled {
        background-color: rgba($blue-900, 0.1);
        color: $color-text-mid;
      }
    }

    &.link {
      background: none;
      border: none;
      color: var(--dig-Button-primary--background-color);

      &:not([disabled]):hover,
      &:not([disabled]):active {
        color: var(--dig-Button-primary-hover--background-color);
      }

      &.disabled {
        color: $color-text-light;
        opacity: 0.7;
        border-color: rgba($color-text-mid, 0.7);

        &:hover {
          background: none;
        }
      }
    }

    &.selectable {
      background-color: $white;
      border: 1px solid $grey-500;
      color: $color-text-darker;

      &:disabled {
        color: $color-text-lighter;

        &,
        &.selected {
          border-color: rgba(0, 57, 77, 0.1);
        }
      }

      &:not(:disabled) {
        &:hover {
          background-color: $blue-050;
          border-color: var(--dig-Button-primary-hover--background-color);
        }

        &:active {
          background-color: $blue-100;
        }
      }

      &:last-of-type {
        border-right: 1px solid $grey-500;
        &:disabled {
          border-right-color: rgba(0, 57, 77, 0.1);
        }
      }

      &.selected {
        background-color: $blue-100;
        border-color: var(--dig-Button-primary--background-color);

        &:disabled {
          background-color: $grey-100;
        }

        &:not(:disabled) {
          &:last-of-type {
            border-right-color: var(--dig-Button-primary--background-color);
          }

          &:active {
            background-color: $white;
          }
        }
      }
    }

    &.processing {
      background-image: url(./loader_@2x.gif);
      background-repeat: no-repeat;
      background-position: center;

      // This used to be "75%" but it only looked good for
      // certain buttons. By using a static value it should
      // look good everywhere. Where this used to be used
      // at 75%, it should still look exactly the same as
      // it did.
      background-size: 110px auto;

      position: relative;

      // Override the global button
      opacity: 1;

      // Hide the text in the button. This may need another solution if you put
      // any non-text content in the button.
      color: transparent !important;
    }
  }

  :global(.dropboxIntegration) {
    $button-height: 40px;

    button.button {
      padding: 8px 20px;

      &.primary {
        border-radius: 0;
        height: $button-height;
        background-color: $dropbox-dark-blue;
        font-family: AtlasGrotesk Medium;
        font-size: 14px;
        font-weight: var(--type__body__standard--fontweight_strong);

        &:not([disabled]):hover {
          border-color: lighten($dropbox-dark-blue, 10%) !important;
          background-color: lighten($dropbox-dark-blue, 10%) !important;
        }

        // TODO: Replace with DBX loading asset
        &.processing,
        &:not([disabled]):active {
          background-image: none;
          color: $white !important;
        }

        &.disabled {
          background-color: #a6bdeb;
          font-family: AtlasGrotesk;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
        }
      }
    }
  }
}
