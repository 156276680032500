.container {
  width: 100%;
  text-align: center;
  padding: var(--spacing__unit--1);
  padding-bottom: var(--spacing__unit--3);
}

.illustration {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;

  svg {
    margin-top: -6%;
  }
}

.errorHelpText {
  margin: 0 auto;
  width: 100%;
  max-width: 620px;
}

.errorInfo {
  padding-top: var(--spacing__unit--2);
}

.contactUsButton {
  padding-top: var(--spacing__unit--3);
  padding-bottom: var(--spacing__unit--4);
}

.sentryNotice {
  margin-top: var(--spacing__unit--3);
  padding: var(--spacing__unit--2);
  background-color: var(--dig-color__warning__surface);
}

.bugIcon {
  vertical-align: bottom;
}

.sentryReportingBanner,
.errorStackBanner {
  margin-bottom: var(--spacing__unit--2);
}

.sentryReportingBanner,
.errorStackBanner {
  overflow-x: auto;

  span {
    width: 100%;
  }
}

.errorStack {
  text-align: left;
}
