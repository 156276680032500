.skipToContentLink {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}

.skipToContentLink:not(:focus) {
  transform: translateY(-110%);
}
