@import '../../scss/mixins';

.condor-layout {
  .row {
    width: auto;
    margin: auto;
    max-width: none;

    @include mobile {
      // Slim gutters on mobile.
      .col,
      [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  p {
    letter-spacing: 0.025rem;
  }
}
