.ariaLive {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

/* for debugging:
document.body.classList.toggle('showAria')
*/
:global(.showAria) .ariaLive {
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  clip: unset;
  background: green;
  font-size: 20px;
}
/* I'm using margin on the children insted of padding on .ariaLive so that
  it can fully collapse when empty. */
:global(.showAria) .ariaLive > div {
  margin: 8px;
}
