@import '_text.scss';
@import 'colors.scss';

:local {
  %banner-base {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    min-height: 40px;
    border-radius: 2px;
    padding: 8px 36px 8px 8px;
    max-width: 780px;
    margin: 0 auto 10px auto;

    display: flex;
    align-items: center;
    overflow-y: auto;

    & > svg {
      margin: 0 5px;
    }
  }

  .showOnTheTop {
    position: fixed;
  }

  .container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
  }

  .icon {
    min-width: 1.5em;
    min-height: 1.5em;
  }

  .error {
    @extend %banner-base;

    background-color: $background-banner-error;
    border: 1px solid $border-banner-error;
  }

  .info {
    @extend %banner-base;

    background-color: $background-banner-info;
    border: 1px solid $border-banner-info;
  }

  .success {
    @extend %banner-base;

    background-color: $background-banner-success;
    border: 1px solid $border-banner-success;

    .icon {
      color: #24b34a;
    }
  }

  .warning {
    @extend %banner-base;

    background-color: $background-banner-warning;
    border: 1px solid $border-banner-warning;
  }

  .closeBox {
    position: absolute;
    height: 1em;
    width: 1em;
    right: 8px;
    cursor: pointer;
  }
}
