/**
 * Font: Atlas Grotesk Grotesk
 */
@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('./fonts/atlas_grotesk/AtlasGrotesk-Regular-Web.woff2') format('woff2'),
    url('./fonts/atlas_grotesk/AtlasGrotesk-Regular-Web.woff') format('woff'),
    url('./fonts/atlas_grotesk/AtlasGrotesk-Regular-Web.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('./fonts/atlas_grotesk/AtlasGrotesk-Regular-Italic-Web.woff2') format('woff2'),
    url('./fonts/atlas_grotesk/AtlasGrotesk-Regular-Italic-Web.woff') format('woff'),
    url('./fonts/atlas_grotesk/AtlasGrotesk-Regular-Italic-Web.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('./fonts/atlas_grotesk/AtlasGrotesk-Medium-Web.woff2') format('woff2'),
    url('./fonts/atlas_grotesk/AtlasGrotesk-Medium-Web.woff') format('woff'),
    url('./fonts/atlas_grotesk/AtlasGrotesk-Medium-Web.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('./fonts/atlas_grotesk/AtlasGrotesk-Medium-Italic-Web.woff2') format('woff2'),
    url('./fonts/atlas_grotesk/AtlasGrotesk-Medium-Italic-Web.woff') format('woff'),
    url('./fonts/atlas_grotesk/AtlasGrotesk-Medium-Italic-Web.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/**
 * Font: Sharp Grotesk (Cyrillic supported)
 */
@font-face {
  font-family: 'Sharp Grotesk';
  src: url('./fonts/sharp_grotesk/SharpGroteskDBCyrBook20.woff2') format('woff2'),
    url('./fonts/sharp_grotesk/SharpGroteskDBCyrBook20.woff') format('woff'),
    url('./fonts/sharp_grotesk/SharpGroteskDBCyrBook20.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sharp Grotesk';
  src: url('./fonts/sharp_grotesk/SharpGroteskDBCyrBookItalic20.woff2') format('woff2'),
    url('./fonts/sharp_grotesk/SharpGroteskDBCyrBookItalic20.woff') format('woff'),
    url('./fonts/sharp_grotesk/SharpGroteskDBCyrBookItalic20.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Sharp Grotesk';
  src: url('./fonts/sharp_grotesk/SharpGroteskDBCyrMedium20.woff2') format('woff2'),
    url('./fonts/sharp_grotesk/SharpGroteskDBCyrMedium20.woff') format('woff'),
    url('./fonts/sharp_grotesk/SharpGroteskDBCyrMedium20.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Sharp Grotesk';
  src: url('./fonts/sharp_grotesk/SharpGroteskDBCyrMediumItalic20.woff2') format('woff2'),
    url('./fonts/sharp_grotesk/SharpGroteskDBCyrMediumItalic20.woff') format('woff'),
    url('./fonts/sharp_grotesk/SharpGroteskDBCyrMediumItalic20.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Korean Support - Does not support italics */
@font-face {
  font-family: 'Sharp Grotesk KR';
  src: url('./fonts/sharp_grotesk/korean/SharpGroteskDBKO-Book20.woff2') format('woff2'),
    url('./fonts/sharp_grotesk/korean/SharpGroteskDBKO-Book20.woff') format('woff'),
    url('./fonts/sharp_grotesk/korean/SharpGroteskDBKO-Book20.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sharp Grotesk KR';
  src: url('./fonts/sharp_grotesk/korean/SharpGroteskDBKO-Medium20.woff2') format('woff2'),
    url('./fonts/sharp_grotesk/korean/SharpGroteskDBKO-Medium20.woff') format('woff'),
    url('./fonts/sharp_grotesk/korean/SharpGroteskDBKO-Medium20.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/**
  * Thai language support
  */
@font-face {
  font-family: 'Sharp Grotesk Thai';
  src: url('./fonts/sharp_grotesk/thai/SharpGroteskDBThaiBook-20.woff2') format('woff2'),
    url('./fonts/sharp_grotesk/thai/SharpGroteskDBThaiBook-20.woff') format('woff'),
    url('./fonts/sharp_grotesk/thai/SharpGroteskDBThaiBook-20.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sharp Grotesk Thai';
  src: url('./fonts/sharp_grotesk/thai/SharpGroteskDBThaiMedium-20.woff2') format('woff2'),
    url('./fonts/sharp_grotesk/thai/SharpGroteskDBThaiMedium-20.woff') format('woff'),
    url('./fonts/sharp_grotesk/thai/SharpGroteskDBThaiMedium-20.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
