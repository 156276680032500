@import 'colors';
@import 'mixins';

.hello-modal {
  height: 100%;

  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.hello-modal__overlay--afterOpen {
  z-index: 1000;

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1024; // Signer app problems
}

.hello-modal__content {
  position: fixed;
  left: 50%;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-right: auto;
  margin-left: auto;

  // There are defaults.
  // Override with CSS by passing `contentClasses` to <HelloModal />
  width: 100%;
  min-width: 400px;
  max-width: 540px;

  background: rgb(255, 255, 255);

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a1a1a;

  &:focus {
    outline: none;
  }

  .hello-modal--compact & {
    padding: 0px;
  }

  &--with-flag {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }

  &--fullscreen {
    top: 0;
    left: 0;
    transform: none;
    max-width: 100%;
    height: 100%;
  }

  @include mobile {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: none;
    max-width: 100%;
    min-width: 0;
    height: 100%;
  }
}

.hello-modal__flag {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #2b4e66;
  width: 100%;
  padding: 24px;
  text-align: center;

  @include mobile {
    display: none;
  }
}

.hello-modal__flag-content {
  @extend .hello-modal__flag;
  background: #1e4f68;
  width: 50%;
  padding: 0;
  margin: 0;
}

.hello-modal__flag-header {
  width: 18rem;
  color: #e7ebee;
  font-size: 2rem;
  font-weight: lighter;
}

.hello-modal__flag-image {
  max-width: 305px;
}

.hello-modal__flag-hs-logo {
  position: absolute;
  top: 0;
  left: 0;
}

.hello-modal__title {
  min-height: 60px;
  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: var(--type__body__standard--fontweight_strong);
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #1a1a1a;

  .hello-modal--compact & {
    padding: 0px;
  }
}

.hello-modal__contentLabel {
  flex-grow: 1;
  padding: 16px 12px 16px 24px;
}

.hello-modal__body {
  display: flex;
  flex-direction: column;
  padding: 8px 24px 24px 24px;
  max-height: 75vh;

  .hello-modal--compact & {
    padding: 0px;
  }

  @include mobile {
    max-height: none;
    min-height: 100%;
  }
}
.hello-modal__body--scrollable {
  overflow-y: auto;
}

.hello-modal__close {
  // position: absolute;
  flex-shrink: 1;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 0.35;
  filter: alpha(opacity=35);
  transition: opacity 0.35s ease;
  will-change: opacity;
  background: transparent; // Reset legacy <button> styles
  padding: 0; // Reset legacy <button> styles
  margin: 0 24px 0 0 !important; // Override <Button> styles

  &:active {
    outline: none; // Reset legacy <button> styles
  }

  &:focus,
  &:hover {
    background: transparent; // Reset legacy <button> styles
    opacity: 1;
    filter: alpha(opacity=100);
  }

  // Draw the "X" button.
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: $grey-600;
    width: 20px;
    height: 2px;
    border-radius: 2px;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
  }

  &:before {
    transform: rotateZ(45deg);
  }

  &:after {
    transform: rotateZ(-45deg);
  }
}

.hello-modal__footer {
  background-color: $plume-darker;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .hello-modal__content--fullscreen & {
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 5;
  }

  @include mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 5;
  }
}

.hello-modal__footer-disclosure,
.hello-modal__footer-buttons {
  display: inline-flex;
}

.hello-modal__footer-disclosure {
  p {
    font-size: 12px;
    padding-right: 20px;
  }
}

.hello-modal__footer-buttons {
  width: 100%;
  justify-content: flex-end;
}

.hello-modal__footer-disclosure + .hello-modal__footer-buttons {
  width: unset;
  align-items: unset;
}

.hello-modal__error {
  font-size: 14px;
  color: red;
}
