@import 'text';
@import 'colors';
@import '../../scss/mixins';

.condor-notice-bar {
  position: fixed;
  top: 0;
  left: 50%;
  right: 0;
  padding: 20px;
  z-index: 26;
  transform: translateX(-50%);
  width: 80%;
  max-width: 960px;
}

.condor-notice {
  width: 100%;
  margin: 0 auto;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: opacity 0.15s ease;
  animation: notice-in 0.35s;

  & + .condor-notice {
    margin-top: 10px;
  }

  &--success {
    background-color: $green-050;
    border-color: $green-100;
  }

  &--info {
    background-color: $blue-050;
    border-color: $blue-100;
  }

  &--warning {
    background-color: $yellow-050;
    border-color: $yellow-100;
  }

  &--error {
    background-color: $red-050;
    border-color: $red-100;
  }

  &--fade {
    opacity: 0;
    transition-duration: 2s;
  }
}

@keyframes notice-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.condor-notice__message {
  @extend %body;
  margin: 0 10px;
  flex-grow: 1;

  a {
    color: $grey-900;
    text-decoration: underline;
  }
}

.condor-notice__icon {
  height: 24px;

  svg {
    margin: 0;
  }
}

.condor-notice__close {
  min-width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    width: 20px;
    height: 2px;
    position: absolute;
    left: 2px;
    top: 11px;
    background: $grey-500;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
}
