@import 'colors';
@import '../../scss/vendor/bootstrap-grid.min';
@import '../../scss/mixins';
@import './overrides';

$footer-height: 73px;
$footer-top-padding: 100px;
$sidebar-width: 300px;

.condor-layout {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;

  *:default {
    font-size: 14px;
  }

  button * {
    font-size: 12px;
  }

  &--is-sidebar-enabled {
    .condor-layout__content {
      padding-left: $sidebar-width;

      // Fill remaining space not used by sidebar.
      flex-grow: 1;

      @include mobile {
        padding-left: 0;
      }
    }
  }

  &--is-sidebar-open {
    @include mobile {
      // overflow: hidden;
      height: 100%;
    }
  }

  .condor-notice-bar {
    position: fixed;
    left: $sidebar-width;
    padding: 20px;
    z-index: 12;
    transform: none;
    width: auto;
    max-width: 100%;

    @include mobile {
      left: 0;
    }
  }
}

.condor-layout__sidebar {
  width: $sidebar-width;
  background: $plume-darker;
  position: fixed;
  height: 100%;
  z-index: 12;

  @include mobile {
    width: 75vw;
    height: 100%;
    position: fixed;
    transform: translateX(-100%);
    transition: transform 0.5s ease;

    .condor-layout--is-sidebar-open & {
      transform: translateX(0%);
    }
  }
}

.condor-layout__sidebar-close-button {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 52px;
  height: 52px;
  display: none;

  @include mobile {
    display: block;
  }
}

.condor-layout__sidebar-close {
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 25px;
    right: 14px;
    height: 2px;
    border-radius: 2px;
    width: 22px;
    background-color: $grey;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.condor-layout__content {
  background: $white;
  width: 100%;
}

.condor-layout__main {
  min-height: 100%;
}

.condor-layout__header {
  @include mobile {
    position: sticky;
    top: 0;
    width: 100%;
    background: $white;
    z-index: 1;
  }
}

.condor-layout__body {
  // overflow: auto;
  padding-bottom: $footer-height + $footer-top-padding;
}

.condor-layout__footer {
  position: relative;
  margin-top: ($footer-height + $footer-top-padding) * -1;
  padding-top: $footer-top-padding;
  height: $footer-height;
  clear: both;
}

.condor-layout__screen {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  transform: translateX(-100%);
  background-color: rgba(52, 58, 64, 0.5);
  transition:
    opacity 0.5s ease,
    transform 0s 0.5s;
  will-change: opacity;

  .condor-layout--is-sidebar-open & {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 0.5s ease 0.1s,
      transform 0s;
  }
}
