.m-button,
.m-button:not([class^='dig-']) {
  text-align: center;
  outline: none;
  vertical-align: middle;

  .m-button--icon {
    width: auto;
    height: auto;
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }

  .m-button--icon-with-text {
    margin-right: 4px;
    margin-bottom: 5px;
    display: inline-block;
  }

  span {
    vertical-align: middle;
  }
}

.m-button.with-icon,
.m-button.with-icon:not([class^='dig-']) {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 18px;
}
