@import 'fonts';
@import 'colors';
@import 'mixins';

:global(.dropboxIntegration) {
  .hello-modal {
    height: 100%;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    &__overlay {
      &--afterOpen {
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(113, 119, 129, 0.5);
        z-index: 1024; // Signer app problems
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;

      span {
        font-family: AtlasGrotesk, Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $dropbox-darkest-blue;
      }

      .deep-integration-modal--compact & {
        padding: 0;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      max-height: 75vh;

      .deep-integration-modal--compact & {
        padding: 0px;
      }

      &--scrollable {
        overflow-y: auto;
      }

      @include mobile {
        max-height: none;
        min-height: 100%;
      }
    }

    &__close {
      cursor: pointer;
      filter: alpha(opacity=35);
      position: relative;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      will-change: opacity;
      background: transparent; // Reset legacy <button> styles
      padding: 0; // Reset legacy <button> styles
      margin: 0; // Reset legacy <button> styles
      opacity: 1;

      &:active {
        outline: none; // Reset legacy <button> styles
      }

      &:focus,
      &:hover {
        background: transparent; // Reset legacy <button> styles
      }

      // Draw the "X" button.
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: $dropbox-darkest-blue;
        width: 18px;
        height: 1px;
        border-radius: 1px;
      }

      &:before {
        transform: rotateZ(45deg);
      }

      &:after {
        transform: rotateZ(-45deg);
      }
    }

    &__primary-button {
      background-color: $dropbox-dark-blue !important;
      border-color: $dropbox-dark-blue !important;
      border-radius: 4px;

      span {
        font-family:
          AtlasGrotesk Medium,
          Helvetica,
          Arial,
          sans-serif;
        font-size: 14px;
        font-weight: var(--type__body__standard--fontweight_strong);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
      }

      &:hover,
      &:focus {
        color: $white;
        border-color: lighten($dropbox-dark-blue, 10%) !important;
        background-color: lighten($dropbox-dark-blue, 10%) !important;
      }
    }

    &__secondary-button {
      font-family: AtlasGrotesk, Helvetica, Arial, sans-serif;
      background-color: $white !important;
      border-radius: 4px;
      border: solid 1px $dropbox-darkest-blue !important;

      span {
        font-family: AtlasGrotesk, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
      }

      &:hover,
      &:focus {
        color: $white;
        border-color: lighten($dropbox-dark-blue, 10%) !important;
        background-color: lighten($dropbox-dark-blue, 10%) !important;
      }
    }

    &__cancel-button {
      background-color: $white !important;
      border-radius: 4px;
      border: solid 1px #c6c9cd;
      color: $dropbox-darkest-blue;

      span {
        font-family: AtlasGrotesk, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
      }

      &:hover,
      &:focus {
        color: $dropbox-darkest-blue;
        background-color: $white !important;
        border-color: #c6c9cd !important;
      }
    }

    &__delete-button {
      background-color: $white !important;
      border-radius: 4px;
      border: solid 1px $red;
      color: $red;

      span {
        font-family: AtlasGrotesk, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
      }

      &:hover,
      &:focus {
        color: $red;
        background-color: $white !important;
        border-color: $red !important;
      }
    }

    &__disabled-button {
      span {
        font-family: AtlasGrotesk, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
      }
    }

    &__footer {
      background-color: #f3f4f5;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 5;
      }
    }

    &__footer-disclosure,
    &__footer-buttons {
      display: inline-flex;
    }

    &__footer-disclosure {
      p {
        font-size: 12px;
        padding-right: 20px;
      }
    }

    &__footer-buttons {
      width: 100%;
      justify-content: flex-end;
    }

    &__footer-disclosure + &__footer-buttons {
      width: unset;
      align-items: unset;
    }
  }
}
