.boxRule {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 1180px;
}

.boxRule.responsive {
  min-width: unset;
  padding-left: 3rem;
  padding-right: 3rem;
}
