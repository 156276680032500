.notificationBanners {
  margin-bottom: var(--spacing__unit--4);
}

.notificationBanner {
  margin-bottom: var(--spacing__unit--1);
}

.notificationBanner.isAnimated {
  animation: slideIn;
  animation-duration: 0.35s;
  animation-iteration-count: 1;
  transition: opacity 0.15s ease;
}

.notificationBanner.isAnimated.isFading {
  opacity: 0;
  transition-duration: 2500ms;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}
