// There are PHP templates that also reference these classes, so they need to
// remain global.  At some point in the future when we remove that PHP code,
// this could be converted to a more standard CSS module.
:global {
  .responsive-layout__sidebar-open {
    overflow: hidden;

    // Do not show banner notices on top layer when sidebar
    // is open
    .condor-notice-bar {
      z-index: 15;
    }

    @media screen and (max-width: 799px) {
      overflow: hidden;

      .responsive-layout__screen {
        opacity: 1;
        transition: opacity 0.3s ease;
        pointer-events: all;
      }

      .responsive-layout__sidebar {
        position: fixed;
        transform: translateX(0);
        transition-duration: 0.3s;
      }

      .responsive-layout__main {
        margin-top: 48.55px; // The height of the header (48px) + underline (0.55px)
      }
    }
  }

  .dig-GlobalHeader,
  .dig-Banner {
    z-index: 25;
  }

  .responsive-layout {
    max-width: 1440px;
    min-width: 320px;
    width: 100%;
    margin: 0 auto;
    height: 100%;

    &--full-bleed-content {
      .responsive-layout__main {
        padding: 0;
      }
    }
  }

  .responsive-layout__screen {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.15s ease;
    z-index: 15;
    background-color: rgba(85, 83, 80, 0.7);
    transform: translateX(0);

    @media screen and (min-width: 901px) {
      display: none;
    }
  }

  .responsive-layout__sidebar-and-content {
    display: flex;
    flex-grow: 1;
  }

  .responsive-layout__header {
    padding-right: 16px;
    background-color: white;
    padding-right: 0;
    height: auto;
    min-width: 320px;
    max-width: 1440px;
    width: 100%;

    @media screen and (max-width: 799px) {
      padding-left: 0;
    }
  }

  .responsive-layout__sidebar {
    position: sticky;
    align-self: flex-start;
    background-color: white;
    min-width: auto; // Min width handled by global-nav.scss
    width: auto; // Allow sidebar to fit content
    top: 49px; // the height of the header

    @media screen and (max-width: 799px) {
      z-index: 20;
      padding-top: 0;
      position: fixed;
      transform: translateX(-100%);
      background: white;
      left: 0;
      top: auto;
      height: 100%;
      transition: transform 0.15s ease;
    }

    .m-app-leftmenu {
      width: 100%;
      padding-top: 0;
    }

    .m-app-leftmenu--fax-numbers {
      margin-right: 0;
    }
  }

  .responsive-layout__wrapper {
    min-height: calc(100vh - 72.55px);
    display: flex;
    flex-direction: column;
    min-width: 320px;
  }

  .responsive-layout__content {
    position: relative;
    padding: 0 24px;
    // Allow sidebar to grow to fit content
    width: auto;
    flex-grow: 1;

    .l-app--content {
      min-width: 320px;
    }
  }

  .responsive-layout__footer {
    height: 76px;
  }

  .responsive-layout__main {
    padding: 24px 0;
    // 100vh - height of header
    min-height: calc(100vh - 49px);
  }
}
