@font-face {
  font-family: 'AtlasGrotesk';
  src: url('../public/fonts/AtlasGrotesk-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AtlasGrotesk Medium';
  src: url('../public/fonts/AtlasGrotesk-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SharpGrotesk Medium';
  src: url('../public/fonts/SharpGroteskDBMedium20.ttf') format('truetype');
}

@font-face {
  font-family: 'SharpGroteskDBBook20';
  src: url('../public/fonts/SharpGroteskDBBook20.ttf') format('truetype');
}
