/**
 * MIXINS
 *
 * This file holds general-purpose SASS mixins that can be applied to all apps.
 * There should not be any reliance on global variables here.
 */

/**
 * Breakpoint mixin
 *
 * This is a simple mixin for generating media query. This mixins assumes a few
 * things:
 *
 * - You've defined your `$breakpoints` as a Sass map with keys as breakpoints
 * and values as the min-width for those keys.
 * - You're working with a "mobile-first" strategy, meaning mobile styles are
 * the default styles and styles for larger screens are defined within media
 * queries
 *
 * @example
 * // my-app/variables.scss
 * $breakpoints: (
 *      m: 40em,
 *      l: 60em
 * )
 *
 * // my-component.scss
 * @import "~my-app/variables"; // Import the variables
 * @import "scss/mixins"; // Import the mixins
 *
 * .my-rule {
 *      // Default rules...
 *      @include breakpoint($breakpoints, 'm') {
            // medium only rules
 *      }
 * }
 */
@mixin breakpoint($breakpoints, $point) {
  @if map-has-key($breakpoints, $point) {
    @media only screen and (min-width: #{map-get($breakpoints, $point)}) {
      @content;
    }
  } @else {
    @error "Breakpoint '#{$point}' not found in breakpoints map: #{$breakpoints}";
  }
}

@mixin mobile {
  // Viewport width is less than 768px (iPad portrait), or
  // the viewport is 900px wide but has an aspect ratio of
  // 3:2 (like an iPhone in landscape).
  @media only screen and (max-width: 767px),
    only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
    @content;
  }
}

@mixin mobileLandscape {
  // The viewport is 900px wide but has a minimum aspect
  // ratio of 3:2 (like an iPhone in landscape).
  @media only screen and (max-width: 900px) and (min-aspect-ratio: 3/2) {
    @content;
  }
}

@mixin mobilePortrait {
  // The viewport is 900px wide but has a maximum aspect
  // ratio of 1:1 (like an iPhone in portrait).
  @media only screen and (max-width: 767px) {
    @content;
  }
}
